import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import title_image from '../images/titles/digital_consulting.jpg'
import person from '../images/person.png'
import ContactText from '../components/ContactText'

const pageTitle = "Digital Consulting Services";
const pageSlug = "digital-consulting-services";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="digital-consulting-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">We can create your digital strategy, personalize your customer 
            experience, and make your marketing plan a success.</h6>
        </div>
      </section>

      <section id="p_digital_solutions" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    No matter where you are on your journey, Nuerex's digital consulting service can help you. By 
                    assessing your strategy, planning your course, and implementing solutions for real results.
                    </h3>
                    <p>Our Interconnected society has made innovation endless; it's a time full of opportunities. 
                        Being a consumer of these new digital services and technologies is excellent; however, what 
                        does this mean for the companies that serve them. As customer experience expectations 
                        increase and demand for more personalized and streamlined experiences grow. Investing in 
                        digital marketing capabilities can improve communications and create significant value.</p>
                    <span className="break"></span>
                    <h4>How our digital consulting service helps</h4>
                    <p>We can personalize your customers' experience, understand your customer data, automate your 
                        marketing operations, and build solutions. Which will allow you to work more efficiently 
                        using up to date delivery methods.</p>
                    <span className="break"></span>
                    <h3 className="intext">Business is never isolated!</h3>
                    <span className="break"></span>
                    <p>Constant digital and technological innovations are empowering business innovation and 
                        transformation, growing and evolving requires you to adapt to new services or designing 
                        your own. With our expertise and experience, we can help you do both.</p>
                    <span className="break"></span>
                    <Link to="/technology-services" className="simple-link">DISCOVER OUR TECHNOLOGY SOLUTIONS</Link>
                    <Link to="/business-consulting" className="simple-link">DISCOVER OUR BUSINESS CONSULTING SERVICES</Link>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>

      <section className="section static-page page-services">
          <div className="container">
              <div className="page-title-area">
                  <h2 className="title">Digital Consulting Services</h2>
                  <h6 className="subtitle">Our experts can create and implement a strategy that is right for you. Here is how we can help:</h6>
              </div>
              <div className="columns is-multiline">
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/digital-strategy"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Digital Strategy</h2>
                        <p className="desc">Use data to develop innovative solutions and customized services. 
                        Define a digital strategy that is guaranteed to win, and leverages the right combination of 
                        products, capabilities, services, and technologies.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/digital-technology-services"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Digital Technology Services</h2>
                        <p className="desc">Our digital technology services span across the entire technology 
                        industry and include project and program management, cloud & mobile 
                        solutions, automation, and workflow.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/test-automation-and-devops-success"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Test Automation & DevOps Success</h2>
                        <p className="desc">DevOps can create a culture where operation and development processes build, 
                    test, and deploy applications to increase product delivery speeds.</p>
                      </Link>
                  </div>

                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/customer-experience-and-design"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Customer Experience and Design</h2>
                        <p className="desc">To deliver a quality customer experience, you must 
                        put the customer at the center of everything you do.</p>
                      </Link>
                  </div>
              </div>
          </div>
      </section>

     <ContactText />

      </Layout>
    )
  }
}

export default BusinessConsulting
